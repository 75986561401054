import React, { useState, useContext, useEffect } from 'react';

import APICtx, { confApi } from '#api'
import { TplWSidebar as Tpl } from '#Template'
import DataList from '#DataList'
import Fab from '#Fab'
import { QrDetails } from '#Dialog'

import { faEdit, faPlus, faUsers, faTrash, faQrcode, faScannerGun } from '@fortawesome/pro-regular-svg-icons'
import EditCompetition from './EditCompetition'

const tabDef = [
	{ label:"Nome", field:'name' },
	{ label:"Data", field:'date', type:'date' },
	{ label:"Colpi per manche", field:'shotsQuantity', type:'integer' },
	{ label:"Colpi scartati", field:'discardsQuantity', type:'integer' },
]

export default function Competitions() {
	const api = useContext(APICtx)
	const [ ids, setIds ] = useState([])
	const [ editCompetition, setEditCompetition ] = useState()
	const [ qrDetails, setQrDetails ] = useState()

	const handleRefresh = () => api.call('competition/list', {}).then(setIds)
	const handleDelete = ({ _id, name }) => {
		api.dialogConfirm({
			text: 'Sei sicuro di voler eliminare la competizione "' + name + '"?',
			onConfirm: () => api.call('competition/del', { _id }).then(handleRefresh),
		})
	}
	useEffect(() => {
		handleRefresh()
	}, [])

	const getRow = _id => api.call('competition/get', { _id })

	const defBtns = [
		{
			icon: faEdit,
			label: 'Modifica',
			onClick: setEditCompetition,
		},
		{
			icon: faUsers,
			label: 'Dati gara',
			linkTo: row => '/competitions/' + row._id,
		},
		{
			icon: faQrcode,
			label: 'QR classifica pubblica',
			onClick: row => setQrDetails({
				label: 'Classifica gara - ' + row.name,
				url: confApi.srvURL + row.rankingAddr,
			}),
		},
		{
			icon: faTrash,
			label: 'Elimina',
			onClick: handleDelete,
		},
	]
	
	return (
		<Tpl title="Gare">
			<QrDetails handleClose={() => setQrDetails(null)} {...qrDetails} />
			<EditCompetition handleRefresh={handleRefresh} handleClose={() => setEditCompetition(null)} {...editCompetition} />

			<DataList def={tabDef} ids={ids} getRow={getRow} rowActions={defBtns} pageSize={30} />
			<Fab label="Crea competizione" icon={faPlus} onClick={() => setEditCompetition({ _id:null })} />
		</Tpl>
	)
}
