import React, { useState, useContext, useEffect, useMemo } from 'react';
import { useParams } from 'react-router-dom';

import APICtx from '#api'
import { TplWSidebar as Tpl } from '#Template'
import DataList from '#DataList'
import Fab from '#Fab'

import { faEdit, faFilePen, faUserPlus, faTrash, faBullseye } from '@fortawesome/pro-regular-svg-icons'
import EditParticipant from './EditParticipant';
import EditManche from './EditManche';

const drawScore = ({ points, mouches } = {}) =>
	points ? (points + ' + ' + mouches + 'M') : '-'

const evalTabDef = ({ mancheCount } = {}) => {
	const def = [
		{ label:"Nominativo", field:'label' },
		{ label:"Migliore / " + mancheCount, content:row => drawScore(row?.highscore) },
	]
	for(let i = 0; i < mancheCount; i++)
		def.push({
			label: '#' + (i+1),
			content:row => drawScore(row?.mancheScore?.[i]),
		})
	return def
}

export default function Participants() {
	const api = useContext(APICtx)
	const { competition } = useParams()
	const [ ids, setIds ] = useState([])
	const [ competitionData, setCompetitionData ] = useState({})
	const [ editParticipant, setEditParticipant ] = useState()
	const [ editManche, setEditManche ] = useState()
	const [ tabDef, setTabDef ] = useState([])

	const handleRefresh = () => {
		setIds([])
		api.call('competition/get', { _id:competition }).then(result => {
			setCompetitionData(result)
			setTabDef(evalTabDef(result))
			api.call('competition/participant/list', { competition }).then(setIds)
		})
	}
	const handleDelete = ({ _id, label }) => {
		api.dialogConfirm({
			text: 'Sei sicuro di voler eliminare il partecipante "' + label + '"?',
			onConfirm: () => api.call('competition/participant/del', { _id }).then(handleRefresh),
		})
	}
	useEffect(() => {
		handleRefresh()
	}, [ competition ])

	const getRow = _id => api.call('competition/participant/get', { _id })

	const defBtns = [
		{
			icon: faBullseye,
			label: 'Aggiungi punteggio manche',
			onClick: setEditManche,
		},
		{
			icon: faEdit,
			label: 'Modifica partecipante',
			onClick: setEditParticipant,
		},
		{
			icon: faFilePen,
			label: 'Dettaglio manche',
			linkTo: row => '/competitions/' + competition + '/manches/' + row._id,
		},
		{
			icon: faTrash,
			label: 'Elimina',
			onClick: handleDelete,
		},
	]
	
	const title = ( competitionData?.name ? competitionData.name+' - ' : '' ) + 'Partecipanti'
	return (
		<Tpl title={title}>
			<EditParticipant handleRefresh={handleRefresh} handleClose={() => setEditParticipant(null)} {...editParticipant} />
			<EditManche
				handleRefresh={handleRefresh}
				handleClose={() => setEditManche(null)}
				_id={editManche ? null : undefined}
				participant={editManche?._id}
				handleRowRefresh={editManche?.handleRowRefresh}
			/>
			{ tabDef.length > 0 && <DataList def={tabDef} ids={ids} getRow={getRow} rowActions={defBtns} pageSize={30} /> }
			<Fab label="Aggiungi partecipante" icon={faUserPlus} onClick={() => setEditParticipant({ _id:null, competition })} />
		</Tpl>
	)
}
